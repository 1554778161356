import { useEffect, useState } from "react";
import GuaranteedViewBox from "../ProductEditView/GuaranteedViewBox/GuaranteedViewBox";
import ProTemplateTabs from "./ProTemplateTabs";
const ProTemplatePriceAndHeading = ({
  children,
  productName,
  productSubTitle,
  productNameSection, subTitleSection,
  handleSectionEditChange,
  productPriceSection,
  productPrice,
  showSalePrice,
  propertiesLength,
  tabsContent,
  tabsSection,
}) => {
  // const { proTemplate } = useSelector((store) => store.proTemplateSlice);
  const [discountPercentage, setDiscountPercentage] = useState(0)
 
  // const sectionText = DOMPurify.sanitize(guaranteeContent?.guaranteeText);
  const regularPrice = productPrice.price
    ? parseFloat(productPrice.price).toFixed(2) || 0.0
    : 0.0;
  const salePrice =
    productPrice.salePrice !== 0 && showSalePrice
      ? parseFloat(productPrice.salePrice).toFixed(2)
      : 0;
  useEffect(() => {
    setDiscountPercentage(regularPrice && salePrice  ? (((regularPrice - salePrice) / regularPrice) * 100).toFixed(2) : 0)
  }, [salePrice, regularPrice])
  console.log("salePrice",salePrice);
  
  return (
    <div className="heading-and-price-section-div">
      <div className="heading-and-price-section">
        <h2
          // id="productName"
          className={`pro-template-prim-heading ${productNameSection ? "outline-border-selected" : "outline-border"
            }`}
          style={{ cursor: "pointer", width: "100%" }}
          onClick={() => handleSectionEditChange(["productName"])}
        >
          {productName}
        </h2>
        <h4
          className={`pro-template-second-heading ${subTitleSection ? "outline-border-selected" : "outline-border"
            }`}
          style={{ cursor: "pointer", width: "100%" }}
          onClick={() => handleSectionEditChange(["subTitleSection"])}
        >{productSubTitle}</h4>{" "}
        <div
          style={{ cursor: "pointer" }}
          onClick={() =>
            propertiesLength <= 1 && handleSectionEditChange(["price"])
          }
          className={`product-price-section ${!propertiesLength <= 1
            ? ""
            : productPriceSection
              ? "outline-border-selected"
              : "outline-border"
            }`}
        >
          <p className="pro-temp-product-price">{`$${regularPrice} USD`}</p>
          <p className="pro-temp-product-discount">
            You save:{" "}
            <span className="pro-temp-discount-price">
              {`              $${  salePrice} USD (${discountPercentage}%)`}
            </span>{" "}
            
          </p>
        </div>
        <>{children}</>
        <p className="pro-temp-tax-info">
          Tax included. <span className="pro-temp-shipping">Shipping</span>{" "}
          calculated at checkout.
        </p>
        <button className="pro-temp-sold-out-button w-full" disabled>
        Buy Now
        </button>
        <GuaranteedViewBox />

        <ProTemplateTabs
          handleSectionEditChange={handleSectionEditChange}
          tabsContent={tabsContent}
          tabsSection={tabsSection}
        />

      </div>
    </div>

  );
};
export default ProTemplatePriceAndHeading;
