import { useEffect, useState } from "react";
import GuaranteedViewBox from "../ProductEditView/GuaranteedViewBox/GuaranteedViewBox";
import "../ProductEditView/PetsKidsEditView.css";
import riskFree from "../../assets/risk-free.png";
import fastShipping from "../../assets/shipping-truck-icon.png";
import returnIcon from "../../assets/return-icon.png";
import verifiedIcon from "../../assets/verified-ico.png";
import { Image } from "antd";
const PetsKidsTemplatePriceHeading = ({
  customTextData,
  children,
  productName,
  productNameSection,
  handleSectionEditChange,
  productPriceSection,
  productPrice,
  showSalePrice,
  propertiesLength,
  testimonialMainSectionText,
  testimonialMainSectionName,
  featureUnderProductNameSection,
  testimonialMainSection,
  shippingRiskFreeReturns,
  mainTestimonialSectionImage,
  bulletBenefits,
  customBadgeText,
  setCustomBadgeText,
  fileList1,
  setFileList1,
  previewImage1,
  setPreviewImage1,
  fileList2,
  setFileList2,
  previewImage2,
  setPreviewImage2,
  fileList3,
  setFileList3,
  previewImage3,
  setPreviewImage3,
  handleCustomBadgeChange,
}) => {
  // const { proTemplate } = useSelector((store) => store.proTemplateSlice);
  const [discountPercentage, setDiscountPercentage] = useState(0);

  // const sectionText = DOMPurify.sanitize(guaranteeContent?.guaranteeText);

  const renderImagePreview1 = () => {
    if (fileList1.length > 0) {
      const file = fileList1[0]; // Get the first image in the file list
      const url = URL.createObjectURL(file.originFileObj); // Use Object URL for preview

      return (
        <Image
          src={url} // Display the image directly from Object URL
          alt="Uploaded Preview"
          style={{ width: "20px", maxHeight: "20px", objectFit: "contain" }}
          preview={false} // Disable the preview icon
        />
      );
    }
    return null;
  };

  const renderImagePreview2 = () => {
    if (fileList2.length > 0) {
      const file = fileList2[0]; // Get the first image in the file list
      const url = URL.createObjectURL(file.originFileObj); // Use Object URL for preview

      return (
        <Image
          src={url} // Display the image directly from Object URL
          alt="Uploaded Preview"
          style={{ width: "20px", maxHeight: "20px", objectFit: "contain" }}
          preview={false} // Disable the preview icon
        />
      );
    }
    return null;
  };

  const renderImagePreview3 = () => {
    if (fileList3.length > 0) {
      const file = fileList3[0]; // Get the first image in the file list
      const url = URL.createObjectURL(file.originFileObj); // Use Object URL for preview

      return (
        <Image
          src={url} // Display the image directly from Object URL
          alt="Uploaded Preview"
          style={{ width: "20px", maxHeight: "20px", objectFit: "contain" }}
          preview={false} // Disable the preview icon
        />
      );
    }
    return null;
  };

  const regularPrice = productPrice.price
    ? parseFloat(productPrice.price).toFixed(2) || 0.0
    : 0.0;
  const salePrice =
    productPrice.salePrice !== 0 &&
    showSalePrice &&
    !isNaN(parseFloat(productPrice.salePrice))
      ? parseFloat(productPrice.salePrice).toFixed(2)
      : "0.00";

  useEffect(() => {
    setDiscountPercentage(
      regularPrice && salePrice
        ? (((regularPrice - salePrice) / regularPrice) * 100).toFixed(2)
        : 0
    );
  }, [salePrice, regularPrice]);
  console.log("salePrice", salePrice);
  const FeatureWithTick = ({ feature }) => {
    let formattedText = feature.startsWith("✓ ") ? feature : `✓ ${feature}`;
    return (
      <div class="pets-kids-temp-feature-div">
        <h2 class=" ">{formattedText}</h2>
      </div>
    );
  };

  const ShippingRiskFreeReturnsIconText = ({
    customBadgeText,
    customTextData,
    setCustomBadgeText,
  }) => (
    <div
      className={`pets-kids-temp-shipping-container ${
        shippingRiskFreeReturns ? "outline-border-selected" : "outline-border"
      }`}
      onClick={() => handleSectionEditChange(["shippingRiskFreeReturns"])}
    >
      <span>
        <div style={{ display: "flex", alignItems: "center", gap: "7px" }}>
          {renderImagePreview1() ? (
            renderImagePreview1()
          ) : (
            <img src={fastShipping} width={20} height={20} />
          )}
          {customBadgeText?.text1}
        </div>
      </span>
      <span>
        <div style={{ display: "flex", alignItems: "center", gap: "7px" }}>
          {renderImagePreview2() ? (
            renderImagePreview2()
          ) : (
            <img src={riskFree} width={20} height={20} />
          )}
          {customBadgeText?.text2}
        </div>
      </span>
      <span>
        <div style={{ display: "flex", alignItems: "center", gap: "7px" }}>
          {renderImagePreview3() ? (
            renderImagePreview3()
          ) : (
            <img src={returnIcon} width={20} height={22} />
          )}
          {customBadgeText?.text3}
        </div>
      </span>
    </div>
  );

  const CustomerReviewBox = ({
    testimonialMainSectionText,
    testimonialMainSectionName,
    mainTestimonialSectionImage,
  }) => (
    <div className="py-3 w-full">
      <div
        onClick={() => handleSectionEditChange(["testimonialMainSection"])}
        className={`cursor-pointer pets-temp-heading-customer-rating-container !rounded-none ${
          testimonialMainSection ? "outline-border-selected" : "outline-border"
        }`}
      >
        <div className="img-rvw">
          <img src={mainTestimonialSectionImage} width={200} height={153} />
          <h4 style={{ color: "black" }}>{testimonialMainSectionName}</h4>
          <span className="verify-cust">
            <img src={verifiedIcon} width={20} height={20} />
            Verified Customer
          </span>
        </div>
        <div className="cont-rvw">
          <p>{testimonialMainSectionText}</p>
        </div>
      </div>
    </div>
  );
  return (
    <div className="heading-and-price-section-div">
      <div className="heading-and-price-section">
        <h2
          className={`pro-template-prim-heading  cursor-pointer${
            productNameSection ? "outline-border-selected" : "outline-border"
          }`}
          style={{
            cursor: "pointer",
            width: "100%",
            fontSize: "48px",
            fontWeight: "600",
          }}
          onClick={() => handleSectionEditChange(["productName"])}
        >
          {productName}
        </h2>
        <div
          className={`cursor-pointer ${
            featureUnderProductNameSection
              ? "outline-border-selected"
              : "outline-border"
          }`}
          onClick={() =>
            handleSectionEditChange(["featureUnderProductNameSection"])
          }
        >
          {bulletBenefits.map((feature, index) => (
            <FeatureWithTick key={index} feature={feature.replace(/,/g, "")} />
          ))}

          {/* <FeatureWithTick feature={featureUnderProductName2} />
                    <FeatureWithTick feature={featureUnderProductName3} />
                    <FeatureWithTick feature={featureUnderProductName4} /> */}
        </div>

        <div
          style={{ cursor: "pointer" }}
          onClick={() =>
            propertiesLength <= 1 && handleSectionEditChange(["price"])
          }
          className={`product-price-section ${
            !propertiesLength <= 1
              ? ""
              : productPriceSection
              ? "outline-border-selected"
              : "outline-border"
          }`}
        >
          <p className="pro-temp-product-price">{`$${regularPrice} USD`}</p>
          <p className="pro-temp-product-discount">
            You save:{" "}
            <span className="pro-temp-discount-price">
              {`              $${salePrice} USD (${discountPercentage}%)`}
            </span>{" "}
          </p>
        </div>
        <>{children}</>
        <p className="pro-temp-tax-info">
          Tax included. <span className="pro-temp-shipping">Shipping</span>{" "}
          calculated at checkout.
        </p>
        <button className="pro-temp-sold-out-button w-full" disabled>
          Buy Now
        </button>

        <ShippingRiskFreeReturnsIconText
          customBadgeText={customBadgeText}
          customTextData={customTextData}
          fileList1={fileList1}
          setFileList1={setFileList1}
          fileList2={fileList2}
          setFileList2={setFileList2}
          fileList3={fileList3}
          setFileList3={setFileList3}
        />
        <CustomerReviewBox
          {...{
            testimonialMainSectionText,
            testimonialMainSectionName,
            mainTestimonialSectionImage,
          }}
        />
      </div>
    </div>
  );
};
export default PetsKidsTemplatePriceHeading;
